<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/order/add" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item label="用户手机" prop="name">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px;margin-right:5px;"
          v-model="keyword"
          @change="getKeyword"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
        <el-button type="primary" @click="exportExcel">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="userId"  label="用户ID"></el-table-column>
      <el-table-column prop="userPhone" label="用户手机"> </el-table-column>
     
      <el-table-column prop="accessPage" label="浏览页面"> </el-table-column>
      <el-table-column prop="accessContent" label="浏览内容"> </el-table-column>
      <el-table-column label="时间">
        <template slot-scope="scope">
          {{scope.row.createdAt|formatDate('yyyy-M-d H:m:s')}}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
  
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      loading: false,
      keyword: "",
      total: 0,
      tableData: [],
      statistics:{},
      crumb:['用户浏览日志','列表']
    };
  },
  methods: {
    exportExcel() {
      let _data={}
      if(this.keyword.length>0){
        _data.userPhone=this.keyword
      }
      operate.exportAccessLog(_data).then((res) => {
        let fileUrl = window.URL.createObjectURL(new Blob([res]))
                let a = document.createElement('a')
                a.style.display = "none"
                a.href = fileUrl
                a.setAttribute('download', `浏览日志.csv`) // 注意文件后缀
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(a.href)
                document.body.removeChild(a)
      });
    },
    searchput() {
      this.getList(1)
    },
    getKeyword() {},
    getList(p) {
      let data={}
      if(this.keyword.length>0){
        data={ pageIndex: p, userPhone: this.keyword }
      }else{
        data={ pageIndex: p}
      }
      operate.getAccessLog(data).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
    },
  },
  mounted() {
    operate.getAccessLog({ pager: 1 }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
  },
};
</script>