import request from "@/utils/request";
const operate = {
    //文章
    addArticle: function (params) {
        return request({
            url: "article/add",
            method: 'put',
            data: params
        });
    },
    deleteArticle: function (id = 0) {
        return request({
            url: "/article/del/" + id,
            method: 'delete'
        })
    },
    editArticle: function (params, method) {
        return request({
            url: "article/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getArticleInfo: function (id = 0) {
        return request({
            url: "article/get/" + id,
            method: 'get'
        })
    },
    getArticleList: function (params) {
        return request({
            url: "/article/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    //轮播
    addLunbo: function (params) {
        return request({
            url: "lunbo/add",
            method: 'put',
            data: params
        });
    },
    deleteLunbo: function (id = 0) {
        return request({
            url: "/lunbo/del/" + id,
            method: 'delete'
        })
    },
    editLunbo: function (params, method) {
        return request({
            url: "lunbo/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getLunboInfo: function (id = 0) {
        return request({
            url: "lunbo/get/" + id,
            method: 'get'
        })
    },
    getLunboList: function (params) {
        return request({
            url: "/lunbo/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    //老师
    addDevice: function (params) {
        return request({
            url: "device/add",
            method: 'put',
            data: params,
            params: params
        });
    },
    deleteDevice: function (id = 0) {
        return request({
            url: "/device/del/" + id,
            method: 'delete'
        })
    },
    editDevice: function (params, method) {
        return request({
            url: "device/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getDeviceInfo: function (id = 0) {
        return request({
            url: "device/get/" + id,
            method: 'get'
        })
    },
    getDeviceList: function (params) {
        return request({
            url: "/device/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    getDeviceStatistics: function (params) {
        return request({
            url: "/device/statistics",
            method: 'get',
            data: params,
            params: params
        })
    },
    exportDevice:function(params){
        console.log('params',params)
        return request({
            url: "/device/exportExcel",
            method: 'get',
            responseType: 'blob',
            data: params,
            params: params
        })
    },
    //管理员
    addManager: function (params) {
        return request({
            url: "manager/add",
            method: 'put',
            data: params,
            params: params
        });
    },
    deleteManager: function (id = 0) {
        return request({
            url: "/manager/del/" + id,
            method: 'delete'
        })
    },
    editManager: function (params, method) {
        return request({
            url: "manager/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getManagerInfo: function (id = 0) {
        return request({
            url: "manager/get/" + id,
            method: 'get'
        })
    },
    getManagerList: function (params) {
        return request({
            url: "/manager/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },

    //配置
    getConfigList: function (params) {
        return request({
            url: "/config/list",
            method: 'get',
            data: params,
            params: params
        })
    },
    editConfig: function (params, method) {
        return request({
            url: "/config/batch/save",
            method: 'put',
            data: params
        })
    },

    //用户
    getUserList: function (params) {
        return request({
            url: "/user/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    getUserInfo: function (id = 0) {
        return request({
            url: "user/get/" + id,
            method: 'get'
        })
    },
    editUser: function (params, method) {
        return request({
            url: "user/edit",
            method: method,
            data: params,
            params: params
        })
    },
    deleteUser: function (id = 0) {
        return request({
            url: "/user/del/" + id,
            method: 'delete'
        })
    },
     //用户浏览记录
     getAccessLog: function (params) {
        return request({
            url: "/accessLog/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    exportAccessLog:function(params){
        return request({
            url: "/accessLog/exportExcel",
            method: 'get',
            data: params,
            params: params
        })
    },
     //用户连接记录
    getConnectLog: function (params) {
        return request({
            url: "/connectLog/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    
	 //用户设备设置记录
    getOperateLog: function (params) {
        return request({
            url: "/operateLog/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    exportOperateLog:function(params){
        return request({
            url: "/operateLog/exportExcel",
            method: 'get',
            data: params,
            params: params
        })
    },
    //用户设备
    getUserDeviceList: function (params) {
        return request({
            url: "/userDevice/pagingQuery",
            method: 'get',
            data: params,
            params: params
        })
    },
    getUserInfo: function (id = 0) {
        return request({
            url: "userDevice/get/" + id,
            method: 'get'
        })
    },
}
export default operate;