<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
    <el-form-item label="标题" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-radio-group v-model="ruleForm.type">
        <el-radio :label="0">普通文章</el-radio>
        <el-radio :label="1">关于我们</el-radio>
        <el-radio :label="2">用户协议</el-radio>
        <el-radio :label="3">隐私协议</el-radio>
      </el-radio-group>
      </el-form-item>
      <el-form-item label="详细简介">
        <quill-editor
          class="editor"
          v-model="ruleForm.content"
          ref="contentEditor"
          :options="editorOption"
          @change="onContentChange($event)"
        >
        </quill-editor>
      </el-form-item>
      <el-upload
        class="rich-upload"
        action="/api/fileInfo/upload"
        :multiple="false"
        :show-file-list="false"
        :on-success="richUploadSuccess"
        :headers="headers"
        style="height: 10px"
      ></el-upload>
      <el-form-item>
        <el-button type="primary" @click="submitForm">更新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    //界面组件引用
    quillEditor,
  },
  data() {
    return {
      ruleForm: {
        title: "",
        status:1,
        content: "",
        type: "",
        id:0
      },
      editorOption: {
        placeholder: "请输入",
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
              ["blockquote", "code-block"], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
              [{ script: "sub" }, { script: "super" }], // 上标/下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              // [{ direction: 'rtl' }], // 文本方向
              [{ size: ["small", false, "large", "huge"] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ["clean"], // 清除文本格式
              ["image"], // 链接、图片，需要视频的可以加上video
            ],
            handlers: {
              //此处是图片上传时候需要使用到的
              image: function (value) {
                console.log("value", value);
                if (value) {
                  // 点击图片
                  document.querySelector(".rich-upload input").click();
                }
              },
            },
          },
        },
      },
      headers: {
          'X-PD-TOKEN' : localStorage.getItem('token')
      },
    };
  },
  methods: {
    onContentChange({ quill, html, text }){
      this.content=html
    },
    submitForm() {
      operate.editArticle(this.ruleForm, "post").then((response) => {
        if(response.code=='0000'){
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeUpdatePate");
          }, 2000);
        }
      });
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.ruleForm.image = response.data.filePath;
      }
    },
    uploadRemove(file, fileList) {
      this.ruleForm.image = "";
    },
     //详细描述图片上传
     richUploadSuccess(response, file, fileList) {
      if (response.code == "0000") {
        let quill = this.$refs.contentEditor.quill;
        let _image = response.data.filePath;
        if (_image) {
          if(!quill.getSelection()||quill.getSelection()==null){
            quill = this.$refs.contentEditor.quill;
          }
          // 获取光标所在位置
          let length = quill.getSelection().index;
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, "image", '/api'+_image);
          // 调整光标到最后
          quill.setSelection(length + 1);
        } else {
          // 提示信息，需引入Message
          this.$message.error("图片插入失败！");
        }
      }
    },
    getInfo(id) {
    operate.getArticleInfo(id).then((response) => {
      this.ruleForm = response.data;
      
    });
  },
  },
  props: ["id"],
  created() {
    this.getInfo(this.id);
  },
  watch: {
    id(newval, oldval) {
      let loadingInstance = Loading.service({
        text: "数据获取中",
      });
      this.id = newval;
      this.getInfo(newval);
      setTimeout(() => {
        loadingInstance.close();
      }, 2000);
    },
  },

};
</script>