<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    text-color="#444"
    active-text-color="#409eff"
  style="background-color: #F2F6FF !important;border-bottom: 1px solid #dcdfe6;"
  >
  <h3 v-if="!isCollapse">后台管理</h3>
    <el-menu-item @click="clickmenu(item)" v-for="item in noChildren" :key="item.name" :index="item.name">
      <i :class="item.icon"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
    <el-submenu v-for="itemhasChildren in hasChildren" :key="itemhasChildren.name" :index="itemhasChildren.name">
      <template slot="title" v-if="itemhasChildren.icon">
        <i :class="itemhasChildren.icon"></i>
        <span slot="title">{{ itemhasChildren.label }}</span>
      </template>
      <el-menu-item-group v-for="itemchildren in itemhasChildren.children" :key="itemchildren.name" :index="itemchildren.name">
        <el-menu-item @click="clickmenu(itemchildren)" :index="itemchildren.path">{{ itemchildren.label }}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>


<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
.el-menu-vertical-demo{
  height: 100%;
  /* width: 200px; */
}
h3{
  padding: 20px;
    text-align: center;
}
.el-menu-item, .el-submenu__title {
    background-color: #F2F6FF !important;
}
.el-menu-item-group{
  background-color: #F2F6FF !important;
}
.el-submenu .el-menu-item{
  margin:0px !important;
}
</style>

<script>
import operate from "../api/api.js";
export default {
  data() {
    return {
      menuData: [
        {
          path: "/bd",
          name: "home",
          label: "首页",
          icon: "el-icon-s-home"
        },
        {
          name: "basic",
          label: "基础信息",
          icon:"el-icon-s-order",
          children: [
            {
              path: "/lunbo",
              name: "lunbo",
              label: "轮播图"
            },
            {
              path: "/article",
              name: "article",
              label: "文章"
            }
          ]
        },
        {
          name: "device",
          label: "设备管理",
          icon: "el-icon-s-data",
          children: [
            {
              path: "/device",
              name: "device",
              label: "设备管理"
            }
          ]
        },
        {
          name: "log",
          label: "日志管理",
          icon: "el-icon-s-data",
          children: [
            {
              path: "/accesslog",
              name: "accesslog",
              label: "浏览日志"
            },
            {
              path: "/connectlog",
              name: "connectlog",
              label: "连接日志"
            },
            {
              path: "/operatelog",
              name: "operatelog",
              label: "操作日志"
            }
          ]
        },
        {
          name: "user",
          label: "用户管理",
          icon: "el-icon-s-data",
          children: [
            {
              path: "/user",
              name: "user",
              label: "用户管理"
            },
            {
              path: "/userdevice",
              name: "userdevice",
              label: "用户设备"
            }
          ]
        },
        {
          label: "系统设置",
          name:"sysset",
          icon: "el-icon-s-tools",
          children: [
            {
              path: "/manager",
              name: "ss",
              label: "管理员",
              icon: "el-icon-s-custom"
            },
            {
              path: "/system",
              name: "system",
              label: "系统设置",
              icon: "el-icon-s-custom"
            }
          ]
        }
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {

    },
    handleClose(key, keyPath) {

    },
    clickmenu(item){
      if(this.$route.path!==item.path){
        this.$router.push(item.path)
      }

    }
  },
  computed:{
    noChildren(){
      return this.menuData.filter(item=>!item.children)
    },
    hasChildren(){
      return this.menuData.filter(item=>item.children)
    },
    isCollapse(){
      return this.$store.state.tab.isCollapse
    }
  },
  mounted() {
  }
};
</script>
