<template>
  <div class="right-content">
    <Breadcrumb
      :crumb="crumb"
      addpath="/teacher/add"
      :showButton="false"
    ></Breadcrumb>
    <el-form>
      <el-form-item label="关键字" prop="name">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px;margin-right:5px;"
          v-model="keyword"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
        <el-button type="primary" @click="exportExcel">导出</el-button>
      </el-form-item>
    </el-form>
    <div style="display: flex;justify-content: flex-end;margin-bottom:10px">
      <el-button @click="add">添加</el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="name" label="设备名称"> </el-table-column>
      <el-table-column prop="type" label="型号"> </el-table-column>
      <el-table-column label="添加时间">
        <template slot-scope="scope">
          {{ scope.row.createdAt | formatDate("yyyy-M-d H:m:s") }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="deleteRow(scope.$index, tableData)"
            type="text"
            size="small"
          >
            移除
          </el-button>
          <el-button
            @click.native.prevent="edit(scope.$index)"
            type="text"
            size="small"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
    <el-dialog title="详细信息" :visible.sync="updatePageVisible" width="80%">
      <updatePage :id="editId" @closeUpdatePate="closeUpdatePate"></updatePage>
    </el-dialog>
    <el-dialog title="添加" :visible.sync="addPageVisible" width="80%">
      <addPage @closeAddPate="closeAddPate"></addPage>
    </el-dialog>
  </div>
</template>
  
  <script>
import operate from "../../api/api.js";
import updatePage from "./edit.vue";
import addPage from "./add.vue";
export default {
  components: {
    updatePage,
    addPage,
  },
  data() {
    return {
      loading: false,
      keyword: "",
      total: 0,
      updatePageVisible: false,
      addPageVisible: false,
      editId: 0,
      tableData: [],
      crumb: ["设备", "列表"],
    };
  },
  methods: {
    closeUpdatePate() {
      this.updatePageVisible = false;
    },
    closeAddPate() {
      this.addPageVisible = false;
    },
    changeStatus(e) {
      operate
        .editDevice({ id: e.id, status: e.status ? 0 : 1 }, "post")
        .then((response) => {
          if (response.code == "0000") {
            this.$message({
              message: response.msg,
              type: "success",
              onClose: function () {
                window.location.reload();
              },
            });
          }
        });
    },
    deleteRow(index, rows) {
      let _info = this.tableData[index];
      operate.deleteDevice(_info.id).then((response) => {
        if (response.code == "0000") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    },
    searchput() {
      this.getList(1);
    },
    exportExcel() {
      let _data={}
      if(this.keyword.length>0){
        _data.name=this.keyword
      }
      operate.exportDevice(_data).then((res) => {
        let fileUrl = window.URL.createObjectURL(new Blob([res]))
                let a = document.createElement('a')
                a.style.display = "none"
                a.href = fileUrl
                a.setAttribute('download', `设备.csv`) // 注意文件后缀
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(a.href)
                document.body.removeChild(a)
      });
    },
    add() {
      this.addPageVisible = true;
    },
    edit(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      console.log(this.editId);
      this.updatePageVisible = true;
      // let id = this.tableData[index].id;
      // let path = this.$route.path + "/edit/" + id;
      // this.$router.push(path);
    },
    getList(p) {
      let data = {};
      if (this.keyword.length > 0) {
        data = { pageIndex: p, name: this.keyword };
      } else {
        data == { pageIndex: p };
      }
      operate.getDeviceList(data).then((response) => {
        this.tableData = response.data;
        this.tableData.forEach(function (item, index) {
          if (item.status == 1) {
            item.isEnable = true;
          } else {
            item.isEnable = false;
          }
        });
        this.total = response.total;
      });
    },
  },
  mounted() {
    operate.getDeviceList({ pager: 1 }).then((response) => {
      this.tableData = response.data;
      this.tableData.forEach(function (item, index) {
        if (item.status == 1) {
          item.isEnable = true;
        } else {
          item.isEnable = false;
        }
      });
      this.total = response.total;
    });
  },
};
</script>