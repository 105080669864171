<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/order/add" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item>
        <el-date-picker
          v-model="start_date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择开始时间"
          style="width: 240px;margin-bottom:5px;"
        >
        </el-date-picker>
        <span> - </span>
        <el-date-picker
          class="input-width"
          v-model="end_date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择结束时间"
          style="width: 240px;margin-bottom:5px;"
        >
        </el-date-picker>
        <el-input
          placeholder="请输入省"
          clearable
          size="small"
          style="width: 240px"
          v-model="sheng"
        ></el-input>
        <el-input
          placeholder="请输入市"
          clearable
          size="small"
          style="width: 240px;margin-bottom:5px;"
          v-model="shi"
        ></el-input>
        <el-input
          placeholder="请输入设备编码"
          clearable
          size="small"
          style="width: 240px;margin-bottom:5px;"
          v-model="device_code"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
   
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="userId"  label="用户ID"></el-table-column>
      <el-table-column prop="deviceId" label="设备ID"> </el-table-column>
     
      <el-table-column prop="deviceCode" label="设备编码"> </el-table-column>
      <el-table-column prop="sheng" label="省"> </el-table-column>
      <el-table-column prop="shi" label="市"> </el-table-column>
      <el-table-column prop="qu" label="区/县"> </el-table-column>
      <el-table-column label="时间">
        <template slot-scope="scope">
          {{scope.row.createdAt|formatDate('yyyy-M-d H:m:s')}}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
  
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      loading: false,
      keyword: "",
      start_date: "",
      end_date: "",
      sheng:"",
      shi:"",
      device_code:"",
      total: 0,
      tableData: [],
      statistics:{},
      crumb:['链接设备日志','列表']
    };
  },
  methods: {
    // exportExcel() {
    //   let _data={}
    //   if(this.keyword.length>0){
    //     _data.name=this.keyword
    //   }
    //   operate.export(_data).then((res) => {
    //     let fileUrl = window.URL.createObjectURL(new Blob([res]))
    //             let a = document.createElement('a')
    //             a.style.display = "none"
    //             a.href = fileUrl
    //             a.setAttribute('download', `链接日志.csv`) // 注意文件后缀
    //             document.body.appendChild(a)
    //             a.click()
    //             window.URL.revokeObjectURL(a.href)
    //             document.body.removeChild(a)
    //   });
    // },
    searchput() {
      this.getList(1)
    },
    getKeyword() {},
    getList(p) {
      let data={}
      data={ pageIndex: p}
      if(this.device_code.length>0){
       data.deviceCode=this.device_code
      }
      if(this.sheng.length>0){
       data.sheng=this.sheng
      }
      if(this.shi.length>0){
       data.shi=this.shi
      }
      if (this.start_date) {
        let createStartAt = new Date(this.start_date).valueOf();
        data.createStartAt = createStartAt;
      }
      if (this.end_date) {
        let createEndAt = new Date(this.end_date).valueOf();
        data.createEndAt = createEndAt;
      }
      operate.getConnectLog(data).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
    },
  },
  mounted() {
    operate.getConnectLog({ pageIndex: 1 }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
  },
};
</script>