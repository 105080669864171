<template>
    <div class="right-content">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
      <el-form-item label="小程序APPID" prop="wechat_app_id">
          <el-input v-model="ruleForm.wechat_app_id"></el-input>
        </el-form-item>
        <el-form-item label="小程序密钥" prop="wechat_app_secret">
          <el-input  type="textarea" :rows="3" v-model="ruleForm.wechat_app_secret"></el-input>
        </el-form-item>
        <el-form-item label="腾讯地图开发者秘钥" prop="lbs_developer_key">
          <el-input  type="textarea" :rows="3" v-model="ruleForm.lbs_developer_key"></el-input>
        </el-form-item>
        <el-form-item label="接口域名" prop="wechat_notify_url">
          <el-input  type="textarea" :rows="3" v-model="ruleForm.wechat_notify_url"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </template>
    <script>
  import operate from "../../api/api.js";
  export default {
    data() {
     
      return {
        tableData:[],
        ruleForm: {
          wechat_app_id:'',
          wechat_app_secret:'',
          lbs_developer_key:'',
          wechat_notify_url:''
        }
      };
    },
    methods: {
      onSubmit() {
        let data=[];
        let that=this
        that.tableData.forEach(function(item,index){
          let _data={};
          _data.id=item.id
          _data.value=that.ruleForm[item.name]
          data.push( _data);
        })
        operate.editConfig(data).then((res) => {
          if (res.code == "0000") {
            this.$message({
              message: "更新成功",
              type: "success",
              onClose: function () {
                window.location.reload();
              },
            });
          }
        });
      }
    },
    mounted() {
      let that=this
    operate.getConfigList().then((response) => {
      let _data = response.data;
      that.tableData=_data;
      _data.forEach(function(item,index){
        that.ruleForm[item.name]=item.value
      })
      console.log(that.ruleForm)
    });
  },
  };
  </script>