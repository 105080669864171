import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '@/views/login/login'
import bd from '@/views/bd/bd'


import lunbo from '@/views/lunbo/list'
import lunboedit from '@/views/lunbo/edit'
import lunboadd from '@/views/lunbo/add'

import article from '@/views/article/list'
import articleedit from '@/views/article/edit'
import articleadd from '@/views/article/add'

import device from '@/views/device/list'
import deviceedit from '@/views/device/edit'
import deviceadd from '@/views/device/add'

import manager from '@/views/manager/list'
import manageredit from '@/views/manager/edit'
import manageradd from '@/views/manager/add'


import system from '@/views/system/info'

import main from '@/views/main/main'

import user from '@/views/user/list'
import userinfo from '@/views/user/info'
import userdevice from '@/views/userdevice/list'

import accesslog from '@/views/accesslog/list'
import connectlog from '@/views/connectlog/list'
import operatelog from '@/views/operatelog/list'

Vue.use(VueRouter)
//创建路由组件，将路由和组件进行映射
const routes = [

    {
        path: '/',
        name: 'login',
        component: login,

    },
    {
        path: '/',
        component: main,
        children: [
            {
                path: '',
                component: bd
            },
            {
                path: '/bd',
                component: bd
            },
            //轮播
            {
                path: '/lunbo',
                component: lunbo
            },
            {
                path: '/lunbo/edit/:id',
                component: lunboedit
            },
            {
                path: '/lunbo/edit/',
                component: lunboedit
            },
            {
                path: '/lunbo/add',
                component: lunboadd
            },
            //文章
            {
                path: '/article',
                component: article
            },
            {
                path: '/article/edit/:id',
                component: articleedit
            },
            {
                path: '/article/edit/',
                component: articleedit
            },
            {
                path: '/article/add',
                component: articleadd
            },
            // 设备
            {
                path: '/device',
                component: device
            },
            {
                path: '/device/edit/:id',
                component: deviceedit
            },
            {
                path: '/device/edit/',
                component: deviceedit
            },
            {
                path: '/device/add',
                component: deviceadd
            },
            //管理员
            {
                path: '/manager',
                component: manager
            },
            {
                path: '/manager/edit/:id',
                component: manageredit
            },
            {
                path: '/manager/edit/',
                component: manageredit
            },
            {
                path: '/manager/add',
                component: manageradd
            },

            //系统设置
            {
                path: '/system',
                component: system
            },

            //用户管理
            {
                path: '/user',
                component: user
            },
            {
                path: '/user/info/:id',
                component: userinfo
            },
            //用户设备
            {
                path: '/userdevice',
                component: userdevice
            },
            //日志
            {
                path: '/accesslog',
                component: accesslog
            },
            {
                path: '/connectlog',
                component: connectlog
            },
            {
                path: '/operatelog',
                component: operatelog
            }
        ]
    },


]
//  创建 router 实例，然后传 `routes` 配置
const router = new VueRouter({
    routes,// (缩写) 相当于 routes: routes,
    mode: 'hash'
})
export default router