<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      :rules="rules"
    >
      <el-form-item label="名称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="图片" prop="image">
        <el-upload
          class="avatar-uploader"
          action="/api/fileInfo/upload"
          :show-file-list="false"
          :on-success="uploadCallBack"
          :on-remove="uploadRemove"
          :headers="headers"
        >
          <img
            v-if="ruleForm.image"
            :src="'/api' + ruleForm.image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div style="font-size:12px;color:red;">200*200</div>
      </el-form-item>
      <el-form-item label="型号" prop="type">
        <el-input v-model="ruleForm.type"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";

export default {
  data() {
    return {
      ruleForm: {
        name: "",
        image: "",
        type: ""
      },
      headers: {
        "X-PD-TOKEN": localStorage.getItem("token"),
      }
    };
  },
  methods: {
    submitForm() {
      for (let i in this.ruleForm) {
        if (this.ruleForm[i].length <= 0) {
          this.$message({
            message: "请完成所有内容",
            type: "error",
          });
          return;
        }
      }
      operate.addDevice(this.ruleForm).then((res) => {
        if (res.code == "0000") {
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeAddPate");
          }, 2000);
        }
      });
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.ruleForm.image = response.data.filePath;;
      }
    },
    uploadRemove(file, fileList) {
      this.ruleForm.image = "";
    }
  },
  mounted() {},
};
</script>