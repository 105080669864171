<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      :rules="rules"
    >
      <el-form-item label="标题" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="轮播图" prop="image">
        <el-upload
          class="avatar-uploader"
          action="/api/fileInfo/upload"
          :show-file-list="false"
          :on-success="uploadCallBack"
          :on-remove="uploadRemove"
          :headers="headers"
        >
          <img
            v-if="ruleForm.image"
            :src="'/api' + ruleForm.image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div style="font-size:12px;color:red;">343*170</div>
      </el-form-item>
      <el-form-item label="是否开启">
        <el-radio-group v-model="ruleForm.status">
        <el-radio :label="0">不开启</el-radio>
        <el-radio :label="1">开启</el-radio>
      </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        image: "",
        type: ""
      },
      rules: {
        name: [{ required: true, message: "请输入标题" }],
        image: [{ required: true, message: "请上传图片" }],
      },
      headers: {
        "X-PD-TOKEN": localStorage.getItem("token"),
      }
    };
  },
  methods: {
    onSubmit() {
      if(this.ruleForm.name.length<=0||this.ruleForm.image.length<=0){
        this.$message({
            message: "请完成必填项",
            type: "error"
          });
          return;
      }
      operate.addLunbo(this.ruleForm).then((res) => {
        if (res.code == "0000") {
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeAddPate");
          }, 2000);
        }
      });
    },
    uploadRemove(file, fileList) {
      this.ruleForm.image = "";
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.ruleForm.image = response.data.filePath;
      }
    },
  },
};
</script>