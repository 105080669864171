<template>
  <div class="right-content">
    <el-descriptions title="用户详情" direction="vertical" :column="4" border>
      <el-descriptions-item label="用户名">{{ruleForm.nickname}}</el-descriptions-item>
      <el-descriptions-item label="头像">
        <el-image
          style="width: 100px; height: 100px"
          :src="'/api'+ruleForm.headimgurl"
          :fit="fit"
        ></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="微信号">{{ruleForm.wechat}}</el-descriptions-item>
      <el-descriptions-item label="剩余积分">{{ruleForm.points}}</el-descriptions-item>
      <el-descriptions-item label="累计充值">{{ruleForm.chongMoney}}</el-descriptions-item>
      <el-descriptions-item label="可提现">{{ruleForm.tiMoney}}</el-descriptions-item>
      <el-descriptions-item label="累计返佣">{{ruleForm.fanMoneyIn}}</el-descriptions-item>
      
    </el-descriptions>
  </div>
</template>
    <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      ruleForm: {},
    };
  },
  methods: {},
  mounted() {
    let param = this.$route.params;
    let id = param["id"];
    operate.getUserInfo(id).then((response) => {
      this.ruleForm = response.data;
    });
  },
};
</script>